import styles from "../styles/overlay.module.css";
import { ReactComponent as Close } from "../assets/cross.svg";

const Overlay = (props: any) => {
  return (
    <div
      className={`${styles.overlay_container} ${
        props.open && styles.overlay_container_open
      }`}
      style={props.style}
    >
      <div className={styles.overlay_head}>
        <h2>{props.title}</h2>
        {props?.hideClose ? "" : <Close onClick={props.onClose} />}
      </div>

      <div className={styles.overlay_hidden}>
        <div className={styles.overlay_content}>{props.children}</div>
      </div>
    </div>
  );
};

export default Overlay;
