import { useEffect, useState } from "react";
import mstyles from "../../styles/checkout.module.css";
import styles from "./billingAddressForCheckout.module.css";

import Overlay from "../overlay";
import InputWithLable from "../InputWithLable";
import axios from "axios";
import toast from "react-hot-toast";

// icons
import { ReactComponent as Truck } from "../../assets/truck.svg";
import { ReactComponent as Down } from "../../assets/arrow-down.svg";
import { ReactComponent as AddCircle } from "../../assets/add-circle.svg";
import { ReactComponent as Edit } from "../../assets/edit-2.svg";
import { ReactComponent as Home } from "../../assets/home.svg";
import { ReactComponent as Office } from "../../assets/briefcase.svg";
import { ReactComponent as Friends } from "../../assets/profile-2user.svg";
import { ReactComponent as Delete } from "../../assets/trash.svg";

const BillingAddressForCheckout = (props: any) => {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [typeName, setTypeName] = useState("");
  const [otherTypeName, setOtherTypeName] = useState("");

  const handleClose = () => {
    setModal(false);
  };

  useEffect(() => {
    if (props.selectedBA) {
      setSelected(props.selectedBA?.saveAs);
    }
  });

  const handleAddressInput = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setName(value);
        break;

      case "address1":
        setAddress1(value);
        break;

      case "address2":
        setAddress2(value);
        break;

      case "landmark":
        setLandmark(value);
        break;

      case "pincode":
        setPincode(value);
        break;

      case "city":
        setCity(value);
        break;

      case "state":
        setState(value);
        break;

      default:
        break;
    }
  };

  const handleSaveClick = () => {
    if (type === "new") {
      addAddress();
    } else if (type.includes("old")) {
      editAddress();
    }
  };

  const addAddress = () => {
    setLoading(true);

    axios({
      url: "https://backend.retaino.in/api/users/" + props.userId,
      method: "PATCH",
      data: {
        billingAddress: [
          ...props.address,
          {
            name,
            addLineOne: address1,
            addLineTwo: address2,
            landmark,
            pincode,
            city,
            state,
            saveAs: typeName === "other" ? otherTypeName : typeName,
          },
        ],
      },
    })
      .then((res) => {
        toast.success("Address added successfully");
        props.fetchAgain();
        setModal(false);
        setName("");
        setAddress1("");
        setAddress2("");
        setLandmark("");
        setPincode("");
        setCity("");
        setState("");
        setTypeName("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong while adding address");
        setLoading(false);
      });
  };

  const editAddress = () => {
    setLoading(true);

    const index = type.split(":")[1];

    const temp = props.address;
    temp[index] = {
      name,
      addLineOne: address1,
      addLineTwo: address2,
      landmark,
      pincode,
      city,
      state,
      saveAs: typeName === "other" ? otherTypeName : typeName,
    };

    axios({
      url: "https://backend.retaino.in/api/users/" + props.userId,
      method: "PATCH",
      data: {
        billingAddress: temp,
      },
    })
      .then((res) => {
        toast.success("Address edited successfully");
        props.fetchAgain();
        setModal(false);
        setName("");
        setAddress1("");
        setAddress2("");
        setLandmark("");
        setPincode("");
        setCity("");
        setState("");
        setTypeName("");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong while editing address");
        setLoading(false);
      });
  };

  const handleEdit = (e: any, index: any, val: any) => {
    e.stopPropagation();
    setType("old:" + index);
    setModal(true);
    setName(val.name);
    setAddress1(val.addLineOne);
    setAddress2(val.addLineTwo);
    setLandmark(val.landmark);
    setPincode(val.pincode);
    setCity(val.city);
    setState(val.state);
    setTypeName(val.saveAs);
  };

  const handleDelete = (e: any, index: any) => {
    e.stopPropagation();

    axios({
      url: "https://backend.retaino.in/api/users/" + props.userId,
      method: "PATCH",
      data: {
        billingAddress: props.address.filter((val: any, i: any) => {
          return i !== index;
        }),
      },
    })
      .then((res: any) => {
        toast.success("Address deleted successfully");
        props.fetchAgain();
      })
      .catch(() => {
        toast.error("Something went wrong while deleting address");
      });
  };

  const handleSelect = (val: any) => {
    setSelected(val?.saveAs);
    props.setSelectedBA(val);

    axios({
      url: "https://backend.retaino.in/api/users/" + props.userId,
      method: "PATCH",
      data: {
        lastBillingAddress: val._id,
      },
    })
      .then(() => {
        console.log("last selected address updated");
      })
      .catch(() => {
        console.log(
          "something went wrong while updating last selected address"
        );
      });
  };

  return (
    <>
      <div className={mstyles.address}>
        <div
          className={`${mstyles.address_head} ${
            props.expand && mstyles.address_head_expand
          }`}
        >
          <Truck />
          <p>Billing Address</p>
          <span
            style={
              selected !== ""
                ? {
                    color: "#5cb85c",
                    padding: "8px 10px",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                  }
                : {}
            }
          >
            {selected !== "" ? selected : "Not selected"}
          </span>
          <Down onClick={props.toggle} />
        </div>

        <div
          className={`${mstyles.addresses} ${
            props.expand && mstyles.addresses_expand
          }`}
        >
          <div className={mstyles.address_wrapper}>
            {true && (
              <div
                className={mstyles.add_new_address}
                onClick={() => {
                  setType("new");
                  setModal(true);
                }}
              >
                <AddCircle /> <p>Add New Address</p>
              </div>
            )}

            {props?.address.map((val: any, index: any) => {
              return (
                <div
                  className={mstyles.old_address}
                  key={index}
                  onClick={() => handleSelect(val)}
                >
                  <div className={mstyles.old_address_head}>
                    <input
                      type="radio"
                      checked={selected === val?.saveAs ? true : false}
                    />
                    <span>{val?.saveAs}</span>
                    <Edit
                      onClick={(e: any) => {
                        handleEdit(e, index, val);
                      }}
                    />
                    <Delete
                      onClick={(e: any) => {
                        handleDelete(e, index);
                      }}
                    />
                  </div>
                  <div className={mstyles.old_address_val}>
                    <p>{val.name}</p>
                    <p>{val.addLineOne}</p>
                    <p>{val.addLineTwo}</p>
                    <p>
                      {val.city}, {val.state} - {val.pincode}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Overlay
        title={"Add New Address"}
        open={modal}
        onClose={handleClose}
        style={{ zIndex: 1000 }}
        hideClose={loading}
      >
        <div className={mstyles.address_modal}>
          <InputWithLable
            label="Name"
            type={"text"}
            name={"name"}
            value={name}
            onChange={handleAddressInput}
          />
          <InputWithLable
            label="House / Flat / Floor no."
            type={"text"}
            name={"address1"}
            value={address1}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="Apartment / Road / Area"
            type={"text"}
            name={"address2"}
            value={address2}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="Landmark"
            type={"text"}
            name={"landmark"}
            value={landmark}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="Pin Code"
            type={"number"}
            name={"pincode"}
            value={pincode}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="City"
            type={"text"}
            name={"city"}
            value={city}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="State"
            type={"text"}
            name={"state"}
            value={state}
            onChange={handleAddressInput}
          />

          <div className={styles.address_type}>
            <p>Select Address Type</p>
            <ul>
              <li
                className={typeName === "home" && styles.active}
                onClick={() => {
                  setTypeName((prev) => (prev === "home" ? "" : "home"));
                }}
              >
                <Home />
                Home
              </li>
              <li
                className={typeName === "work" && styles.active}
                onClick={() => {
                  setTypeName((prev) => (prev === "work" ? "" : "work"));
                }}
              >
                <Office />
                Work
              </li>
              <li
                className={typeName === "F&F" && styles.active}
                onClick={() => {
                  setTypeName((prev) => (prev === "F&F" ? "" : "F&F"));
                }}
              >
                <Friends />
                Friend
              </li>
              <li
                className={typeName === "other" && styles.active}
                onClick={() => {
                  setTypeName((prev) => (prev === "other" ? "" : "other"));
                }}
              >
                Other
              </li>
            </ul>
            {typeName === "other" && (
              <InputWithLable
                label="Address Type"
                type={"text"}
                name={"typeName"}
                value={otherTypeName}
                onChange={(e: any) => {
                  setOtherTypeName(e.target.value);
                }}
              />
            )}
          </div>

          <button
            className={mstyles.proceed_btn}
            onClick={loading ? () => {} : handleSaveClick}
            style={loading ? { opacity: 0.8, cursor: "not-allowed" } : {}}
          >
            {loading ? <span className={mstyles.btn_loading}></span> : "Save"}
          </button>
        </div>
      </Overlay>
    </>
  );
};

export default BillingAddressForCheckout;
