import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Pages/layout";
import Login from "./Pages/login";
import Checkout from "./Pages/checkout";
import Update from "./Pages/update";
import CheckStatus from "./Pages/checkstatus";
import OrderSuccess from "./Pages/orderSuccess";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/checkout/:id", element: <Checkout /> },
        { path: "/update/user/:userId/:token", element: <Update /> },
        { path: "/check_status", element: <CheckStatus /> },
        { path: "/order_success", element: <OrderSuccess /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
