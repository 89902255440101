import axios from "axios";
import { useEffect } from "react";

const CheckStatus = () => {
  console.log(
    "Order Transaction id ::: ",
    localStorage.getItem("OREDER_TXN_ID")
  );

  useEffect(() => {
    const checkStatus = () => {
      axios({
        url: `https://backend.retaino.in/api/phonepe/status/${localStorage.getItem(
          "OREDER_TXN_ID"
        )}`,
        method: "GET",
      })
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    checkStatus();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className="loader"></span>
      </div>
    </>
  );
};

export default CheckStatus;
