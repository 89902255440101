import React, { useEffect, useState } from "react";

import { ReactComponent as Email } from "../../assets/email.svg";
import { ReactComponent as Close } from "../../assets/cross.svg";
import { ReactComponent as Edit } from "../../assets/edit-2.svg";
import { ReactComponent as Lock } from "../../assets/lock.svg";
import retaino from "../../assets/retaino.png";

import styles from "../../styles/login.module.css";
import Overlay from "../../component/overlay";
import { toast } from "react-hot-toast";
import axios from "axios";

const Login = () => {
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  const [counter, setCounter] = useState(0);

  const [open, setOpen] = useState(false);

  const [consent, setConsent] = useState(true);

  const [email, setEmail] = useState("");

  const [otpLoading, setOtpLoading] = useState(false);

  const [otpStatus, setOtpStatus] = useState("");

  const [token, setToken] = useState("");
  const [vToken, setVToken] = useState("");

  useEffect(() => {
    // when window is ready to receive messages
    window.parent.postMessage("retaino_iframe_login_window_ready", "*");

    // when window receives a message
    window.addEventListener("message", (e) => {
      if (typeof e.data === "string") {
        if (e.data.includes("website_token")) {
          setToken(e.data.split(":")[1]);
        }
      }
    });
  }, []);

  useEffect(() => {
    const interval = setTimeout(
      () =>
        setCounter((value) => {
          if (value === 0) return 0;
          return counter - 1;
        }),
      1000
    );

    if (counter > 0) {
    } else if (counter === 0) {
      clearTimeout(interval);
    }
  }, [counter]);

  const handleOtp1 = (e: any) => {
    if (e.target.value.length >= 1) {
      setOtp1(e.target.value.split("")[e.target.value.split("").length - 1]);
      document.getElementById("otp2")?.focus();
    } else {
      setOtp1("");
    }
  };

  const handleOtp2 = (e: any) => {
    if (e.target.value.length >= 1) {
      setOtp2(e.target.value.split("")[e.target.value.split("").length - 1]);
      document.getElementById("otp3")?.focus();
    } else {
      setOtp2("");
      document.getElementById("otp2")?.focus();
    }
  };

  const onKeyDown2 = (e: any) => {
    if (e.key === "Backspace" && otp2 === "") {
      document.getElementById("otp1")?.focus();
    }
  };

  const handleOtp3 = (e: any) => {
    if (e.target.value.length >= 1) {
      setOtp3(e.target.value.split("")[e.target.value.split("").length - 1]);
      document.getElementById("otp4")?.focus();
    } else {
      setOtp3("");
      document.getElementById("otp3")?.focus();
    }
  };

  const onKeyDown3 = (e: any) => {
    if (e.key === "Backspace" && otp3 === "") {
      document.getElementById("otp2")?.focus();
    }
  };

  const handleOtp4 = (e: any) => {
    if (e.target.value.length >= 1) {
      setOtp4(e.target.value.split("")[e.target.value.split("").length - 1]);
      document.getElementById("otp5")?.focus();
    } else {
      setOtp4("");
      document.getElementById("otp4")?.focus();
    }
  };

  const onKeyDown4 = (e: any) => {
    if (e.key === "Backspace" && otp4 === "") {
      document.getElementById("otp3")?.focus();
    }
  };

  const handleOtp5 = (e: any) => {
    console.log("this is what we are getting : :: ", e.target.value.length);
    if (e.target.value.length >= 1) {
      setOtp5(e.target.value.split("")[e.target.value.split("").length - 1]);
      document.getElementById("otp6")?.focus();
    } else {
      setOtp5("");
      document.getElementById("otp5")?.focus();
    }
  };

  const onKeyDown5 = (e: any) => {
    if (e.key === "Backspace" && otp5 === "") {
      document.getElementById("otp4")?.focus();
    }
  };

  const handleOtp6 = (e: any) => {
    if (e.target.value.length >= 1) {
      setOtp6((prev) => {
        console.log(
          "returning value toooo : ",
          e.target.value.split("")[e.target.value.split("").length - 1]
        );
        return e.target.value.split("")[e.target.value.split("").length - 1];
      });
    } else {
      console.log("shitttttttttt");
      setOtp6("");
      document.getElementById("otp6")?.focus();
    }
  };

  useEffect(() => {
    if (otp6) {
      handleOTPFill();
      setCounter(0);
    }
  }, [otp6]);

  const onKeyDown6 = (e: any) => {
    if (e.key === "Backspace" && otp6 === "") {
      document.getElementById("otp5")?.focus();
    }
  };

  const handleOTPFill = () => {
    setOtpLoading(true);

    axios({
      url: "https://backend.retaino.in/api/users/verifyotp/" + vToken,
      method: "POST",
      data: {
        otp:
          String(otp1) +
          String(otp2) +
          String(otp3) +
          String(otp4) +
          String(otp5) +
          String(otp6),
      },
    })
      .then((res) => {
        console.log("OTP Verification response ::: ", res);
        setOtpStatus("success");
        setOtpLoading(false);
        setCounter(60);

        const temp = {
          session: res.data.session,
          userId: res.data.userData.userId,
        };

        window.parent.postMessage(`session_token:${JSON.stringify(temp)}`, "*");

        setTimeout(() => {
          window.parent.postMessage("retaino_close_iframe_window", "*");
        }, 2000);
      })
      .catch((err) => {
        if (err.response.data.message === "Wrong otp") {
          toast.error("Wrong OTP entered");
        }
        setOtpStatus("error");
        setOtpLoading(false);
        setCounter(60);
      });
  };

  const handleRequestOtp = () => {
    if (!email) {
      // show error  for the toast
      toast.error("Please enter your email address");
      return;
    }

    if (!consent) {
      // show error for the toast
      toast.error("Please agree to the terms and conditions");
      return;
    }
    requestOtp();
    setOpen(true);
    setCounter(60);
    //focus otp1 input
    document.getElementById("otp1")?.focus();
  };

  const handleEmailEdit = () => {
    setOpen(false);
    setCounter(0);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setOtp6("");
    document.getElementById("retaino_user_email")?.focus();
  };

  const requestOtp = () => {
    axios({
      url: "https://backend.retaino.in/api/users/verifyuser",
      method: "POST",
      data: {
        email: email,
        key: token,
        consent: true,
      },
    })
      .then((res) => {
        setVToken(res.data.verificationToken);
      })
      .catch((err) => {
        console.log("login error :::: ", err);
      });
  };

  return (
    <>
      {otpStatus === "success" ? (
        <div className={styles.success_container}>
          <span className="loader"></span>
          <p>Logging in user, please wait</p>
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <h2>Login/Create Account</h2>
            <p>Request OTP to access your Account</p>
            <div className={styles.input_container}>
              <div className={styles.input_type1}>
                <Email />
                <input
                  id="retaino_user_email"
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      handleRequestOtp();
                    }
                  }}
                />
              </div>
              <div className={styles.input_type2}>
                <input
                  type="checkbox"
                  checked={consent}
                  onChange={(e: any) => setConsent(e.target.checked)}
                />
                <p>
                  I agree to websites's{" "}
                  <span>
                    <a
                      href={"https://retaino.in/terms"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms
                    </a>
                  </span>{" "}
                  and{" "}
                  <span>
                    <a
                      href={"https://retaino.in/policy"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.btn_section}>
              <button onClick={handleRequestOtp}>Request OTP</button>
              <div className={styles.secured_section}>
                🔒 <p style={{ marginLeft: "5px" }}>Secured by</p>
                <img src={retaino} alt="retaino" />
              </div>
            </div>
          </div>

          <div className={`${styles.overlay} ${open && styles.overlay_show}`}>
            <div className={styles.overlay_head}>
              <h2>Verify OTP</h2>
              <Close
                onClick={() => {
                  setOpen(false);
                  setCounter(0);
                }}
              />
            </div>

            <div className={styles.email_section}>
              <p>Otp has been sent to </p>
              <div className={styles.email}>
                <span>{email}</span>
                <Edit onClick={handleEmailEdit} />
              </div>
            </div>

            <div className={styles.otp_section}>
              <div
                className={`${styles.otp} ${
                  otpStatus === "error"
                    ? styles.error
                    : otpStatus === "success"
                    ? styles.success
                    : ""
                }`}
              >
                <input
                  type="number"
                  id="otp1"
                  value={otp1}
                  onChange={handleOtp1}
                />
              </div>
              <div
                className={`${styles.otp} ${
                  otpStatus === "error"
                    ? styles.error
                    : otpStatus === "success"
                    ? styles.success
                    : ""
                }`}
              >
                <input
                  type="number"
                  id="otp2"
                  value={otp2}
                  onChange={handleOtp2}
                  onKeyDown={onKeyDown2}
                />
              </div>
              <div
                className={`${styles.otp} ${
                  otpStatus === "error"
                    ? styles.error
                    : otpStatus === "success"
                    ? styles.success
                    : ""
                }`}
              >
                <input
                  type="number"
                  id="otp3"
                  value={otp3}
                  onChange={handleOtp3}
                  onKeyDown={onKeyDown3}
                />
              </div>
              <div
                className={`${styles.otp} ${
                  otpStatus === "error"
                    ? styles.error
                    : otpStatus === "success"
                    ? styles.success
                    : ""
                }`}
              >
                <input
                  type="number"
                  id="otp4"
                  value={otp4}
                  onChange={handleOtp4}
                  onKeyDown={onKeyDown4}
                />
              </div>
              <div
                className={`${styles.otp} ${
                  otpStatus === "error"
                    ? styles.error
                    : otpStatus === "success"
                    ? styles.success
                    : ""
                }`}
              >
                <input
                  type="number"
                  id="otp5"
                  value={otp5}
                  onChange={handleOtp5}
                  onKeyDown={onKeyDown5}
                />
              </div>
              <div
                className={`${styles.otp} ${
                  otpStatus === "error"
                    ? styles.error
                    : otpStatus === "success"
                    ? styles.success
                    : ""
                }`}
              >
                <input
                  type="number"
                  id="otp6"
                  value={otp6}
                  onChange={handleOtp6}
                  onKeyDown={onKeyDown6}
                />
              </div>
            </div>

            <div className={styles.otp_timer}>
              {otpLoading ? (
                <span className={"loader"}></span>
              ) : counter === 0 ? (
                <p
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    setCounter(60);
                  }}
                >
                  Resend OTP
                </p>
              ) : (
                <>
                  <p>Resend OTP</p>{" "}
                  <p>
                    in 00:
                    {String(counter).split("").length === 1
                      ? "0" + counter
                      : counter}
                  </p>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
