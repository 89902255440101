import React, { useState } from "react";
import mstyles from "../../styles/checkout.module.css";

import { ReactComponent as Down } from "../../assets/arrow-down.svg";
import { ReactComponent as Wallet } from "../../assets/wallet.svg";

const PaymentMethod = (props: any) => {
  const [selected, setSelected] = useState("");

  return (
    <>
      <div className={mstyles.address}>
        <div
          className={`${mstyles.payment_head} ${
            props.expand && mstyles.payment_head_expand
          }`}
        >
          <Wallet />
          <p>Payment Method</p>
          <span
            style={
              selected !== ""
                ? {
                    color: "#5cb85c",
                    padding: "8px 10px",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                  }
                : {}
            }
          >
            {selected !== ""
              ? selected.includes("online")
                ? "Online"
                : selected
              : "Not selected"}
          </span>
          <Down onClick={props.toggle} />
        </div>
        <div
          className={`${mstyles.payment_body} ${
            props.expand && mstyles.payment_body_expand
          }`}
        >
          <ul>
            <li
              onClick={() => {
                setSelected("upi_online");
                props?.setPaymentType("upi_online");
              }}
              style={{ cursor: "pointer" }}
            >
              <input
                type="radio"
                checked={selected === "upi_online" ? true : false}
              />
              <span>UPI</span>
            </li>
            <li
              onClick={() => {
                setSelected("net_online");
                props?.setPaymentType("net_online");
              }}
              style={{ cursor: "pointer" }}
            >
              <input
                type="radio"
                checked={selected === "net_online" ? true : false}
              />
              <span>Net Banking</span>
            </li>
            <li
              onClick={() => {
                setSelected("card_online");
                props?.setPaymentType("card_online");
              }}
              style={{ cursor: "pointer" }}
            >
              <input
                type="radio"
                checked={selected === "card_online" ? true : false}
              />
              <span>Credit Card / Debit Card</span>
            </li>
            <li
              onClick={() => {
                setSelected("COD");
                props?.setPaymentType("COD");
              }}
              style={{ cursor: "pointer" }}
            >
              <input type="radio" checked={selected === "COD" ? true : false} />
              <span>Pay on Delivery / Cash on Delivery</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
