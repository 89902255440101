import { useEffect } from "react";

const OrderSuccess = () => {
  useEffect(() => {
    setTimeout(() => {
      console.log("called");
      window.parent.postMessage("order_success_redirect_to_order_page", "*");
    }, 1000);
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <span style={{ fontSize: "1.4rem", fontWeight: "500", color: "green" }}>
        Order Placed Successfully.
      </span>
      <p style={{ color: "grey" }}>Redirecting to the orders page.</p>
    </div>
  );
};

export default OrderSuccess;
