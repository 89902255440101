import React, { useEffect, useState } from "react";

import styles from "../../styles/app.module.css";
import cstyles from "../../styles/checkout.module.css";

import { ReactComponent as Back } from "../../assets/arrow-left.svg";
import AccountInfo from "../../component/accountInfo";
import { useParams } from "react-router-dom";
import axios from "axios";
import DeliveryAddress from "../../component/deliveryAddress";

const Update = () => {
  const params = useParams();

  const [user, setUser] = useState({});
  const [address, setAddress] = useState(false);
  const [userId, setUserId] = useState("");
  const [shippingAddress, setShippingAddress]: any = useState([]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    axios({
      url:
        "https://backend.retaino.in/api/users/getuserbytoken/" + params.token,
      method: "GET",
    })
      .then((res: any) => {
        console.log(res.data.user1);
        const data = res.data.user1;
        setUser(data);
        setUserId(data._id);
        setShippingAddress([...data.shippingAddress]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const closeWin = () => {
    window.parent.postMessage("retaino_close_iframe_window", "*");
  };

  return (
    <>
      <div className={styles.card_head}>
        <Back name="close" value={"close"} onClick={closeWin} />
        <h1>Profile</h1>
      </div>
      <div className={cstyles.main}>
        <AccountInfo data={user} fetchAgain={getUserData} />
        <DeliveryAddress
          expand={address}
          toggle={() => setAddress((prev) => !prev)}
          userId={userId}
          address={shippingAddress}
          setAddress={setShippingAddress}
          fetchAgain={getUserData}
        />
      </div>
    </>
  );
};

export default Update;
