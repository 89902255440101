import React, { useEffect, useState } from "react";
import mstyles from "../../styles/checkout.module.css";
import Overlay from "../overlay";
import InputWithLable from "../InputWithLable";

import { ReactComponent as Lock } from "../../assets/lock.svg";
import BillingAddress from "../billingAddress";
import axios from "axios";
import toast from "react-hot-toast";

const AccountModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [businessAccount, setBusinessAccount] = React.useState(false);

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setFirstName(props.data.firstName);
      setLastName(props.data.lastName);
      setEmail(props.data.email);
      setDob(props.data.dob);
      setMobile(props?.data?.mobile?.number);
    }
  }, [props.data]);

  const updateAccountInfo = () => {
    if (!loading) {
      setLoading(true);

      axios({
        url: "https://backend.retaino.in/api/users/" + props.data._id,
        method: "PATCH",
        data: {
          firstName,
          lastName,
          dob,
          mobile: {
            number: mobile,
          },
        },
      })
        .then((res) => {
          setLoading(false);
          toast.success("Account Info Updated");
          props.onClose();
          props.fetchAgain();
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something went wrong while updating account info");
        });
    }
  };

  return (
    <>
      <Overlay
        title={"Account Info"}
        open={props.open}
        onClose={props.onClose}
        style={{ zIndex: 90 }}
      >
        <div className={mstyles.account_info_modal}>
          <InputWithLable
            label="First Name"
            type={"text"}
            name={"name"}
            value={firstName}
            onChange={(e: any) => setFirstName(e.target.value)}
          />
          <InputWithLable
            label="Last Name"
            type={"text"}
            name={"name"}
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
          />
          <InputWithLable
            label="Mobile Number"
            type={"number"}
            name={"name"}
            value={mobile}
            onChange={(e: any) => setMobile(e.target.value)}
          />
          <div className={mstyles.with_icon}>
            <InputWithLable
              label="Email Address"
              type={"text"}
              name={"name"}
              value={email}
              onChange={() => {}}
            />
            <Lock />
          </div>

          <InputWithLable
            label=""
            type={"date"}
            name={"name"}
            value={dob}
            onChange={(e: any) => {
              setDob(e.target.value);
            }}
          />

          <BillingAddress
            fetchAgain={props.fetchAgain}
            data={props.data?.billingAddress}
            userId={props.data?._id}
          />

          <div className={mstyles.business_account_main}>
            <span>Business Account</span>
            <div className={mstyles.business_account_head}>
              <input
                type="checkbox"
                checked={false}
                onChange={(e) => setBusinessAccount(e.target.checked)}
              />
              <span>Create Business Account</span>
            </div>
            <div
              className={`${mstyles.business_account} ${
                businessAccount && mstyles.business_account_expand
              }`}
            >
              <InputWithLable
                label="GST NO."
                type={"text"}
                name={"gstNo"}
                value={""}
                onChange={() => {}}
              />

              <InputWithLable
                label="Business Name"
                type={"text"}
                name={"businessName"}
                value={""}
                onChange={() => {}}
              />
            </div>
          </div>

          <button
            className={mstyles.proceed_btn}
            onClick={updateAccountInfo}
            style={loading ? { pointerEvents: "none" } : {}}
          >
            {loading ? <span className={mstyles.btn_loading}></span> : "Save"}
          </button>
        </div>
      </Overlay>
    </>
  );
};

export default AccountModal;
