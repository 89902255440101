import React from "react";
import mstyles from "../../styles/checkout.module.css";

import { ReactComponent as User } from "../../assets/user.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import AccountModal from "../accountModal";

const AccountInfo = (props: any) => {
  const [modal, setModal] = React.useState(false);

  return (
    <>
      <div
        className={mstyles.order_summary}
        style={{ cursor: "pointer" }}
        onClick={() => setModal(true)}
      >
        <div className={`${mstyles.account_info}`}>
          <User />
          <p>Account Info</p>
          <ArrowRight />
        </div>
      </div>

      <AccountModal
        open={modal}
        onClose={() => setModal(false)}
        data={props.data}
        fetchAgain={props.fetchAgain}
      />
    </>
  );
};

export default AccountInfo;
