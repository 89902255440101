import React, { useEffect, useState } from "react";
import mstyles from "../../styles/checkout.module.css";
import Overlay from "../overlay";
import InputWithLable from "../InputWithLable";

import { ReactComponent as AddCircle } from "../../assets/add-circle.svg";
import { ReactComponent as Edit } from "../../assets/edit-2.svg";
import axios from "axios";
import toast from "react-hot-toast";

const BillingAddress = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [operation, setOperation] = useState("");

  const handleAddressInput = (e: any) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "address1") {
      setAddress1(e.target.value);
    } else if (e.target.name === "address2") {
      setAddress2(e.target.value);
    } else if (e.target.name === "landmark") {
      setLandmark(e.target.value);
    } else if (e.target.name === "pincode") {
      setPincode(e.target.value);
    } else if (e.target.name === "city") {
      setCity(e.target.value);
    } else if (e.target.name === "state") {
      setState(e.target.value);
    }
  };

  useEffect(() => {
    if (typeof props.data === "object" && props.data?.length !== 0) {
      setName(props.data[0].name);
      setAddress1(props.data[0].addLineOne);
      setAddress2(props.data[0].addLineTwo);
      setLandmark(props.data[0].landmark);
      setPincode(props.data[0].pincode);
      setCity(props.data[0].city);
      setState(props.data[0].state);
    }
  }, [props.data]);

  const openUpdateModal = (index: any, val: any) => {
    setName(val.name);
    setAddress1(val.addLineOne);
    setAddress2(val.addLineTwo);
    setLandmark(val.landmark);
    setPincode(val.pincode);
    setCity(val.city);
    setState(val.state);
    setOperation("update:" + index);
    setModal(true);
  };

  const handleBtnClick = () => {
    if (operation.includes("update")) {
      let index = operation.split(":")[1];

      let temp: any = [...props.data];

      temp[index] = {
        name,
        addLineOne: address1,
        addLineTwo: address2,
        landmark,
        pincode,
        city,
        state,
      };

      makeCall(temp);
    } else if (operation === "new") {
      let temp: any = [...props.data];

      temp.push({
        name,
        addLineOne: address1,
        addLineTwo: address2,
        landmark,
        pincode,
        city,
        state,
      });

      makeCall(temp);
    }
  };

  const makeCall = (temp: any) => {
    setLoading(true);

    axios({
      url: "https://backend.retaino.in/api/users/" + props.userId,
      method: "PATCH",
      data: { billingAddress: [...temp] },
    })
      .then((res: any) => {
        setLoading(false);
        setModal(false);
        toast.success("Billing Address Updated");
        props.fetchAgain();
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error("Something went wrong while updating billing address");
      });
  };

  return (
    <>
      <div className={mstyles.billing_address}>
        <span>Billing Address</span>
        {typeof props.data === "object" && props.data?.length !== 0 ? (
          props.data.map((val: any, index: any) => {
            return (
              <div
                className={mstyles.add_new_billing_address_added}
                key={index}
              >
                <p style={{ display: "flex" }}>
                  <input type="checkbox" onChange={() => {}} />
                  {val.name}
                  <Edit
                    style={{
                      fill: "black",
                      height: "20px",
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      openUpdateModal(index, val);
                    }}
                  />
                </p>
                <p>
                  {val.addLineOne},{val.addLineTwo}, {val.landmark}
                </p>
                <p>
                  {val.city}, {val.state} - {val.pincode}
                </p>
              </div>
            );
          })
        ) : (
          <div
            className={mstyles.add_new_billing_address}
            onClick={() => {
              setModal(true);
              setOperation("new");
            }}
          >
            <AddCircle /> <p>Add New Billing Address</p>
          </div>
        )}
      </div>

      {/* modal */}
      <Overlay
        title={"Add New Address"}
        open={modal}
        onClose={() => setModal(false)}
        style={{ zIndex: 1000 }}
      >
        <div className={mstyles.address_modal}>
          <InputWithLable
            label="Name"
            type={"text"}
            name={"name"}
            value={name}
            onChange={handleAddressInput}
          />
          <InputWithLable
            label="House / Flat / Floor no."
            type={"text"}
            name={"address1"}
            value={address1}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="Apartment / Road / Area"
            type={"text"}
            name={"address2"}
            value={address2}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="Landmark"
            type={"text"}
            name={"landmark"}
            value={landmark}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="Pin Code"
            type={"number"}
            name={"pincode"}
            value={pincode}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="City"
            type={"text"}
            name={"city"}
            value={city}
            onChange={handleAddressInput}
          />

          <InputWithLable
            label="State"
            type={"text"}
            name={"state"}
            value={state}
            onChange={handleAddressInput}
          />

          <button className={mstyles.proceed_btn} onClick={handleBtnClick}>
            {loading ? <span className={mstyles.btn_loading}></span> : "Save"}
          </button>
        </div>
      </Overlay>
    </>
  );
};

export default BillingAddress;
