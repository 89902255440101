import styles from "../../styles/app.module.css";

import { ReactComponent as Back } from "../../assets/arrow-left.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";

import logo from "../../assets/logo.png";

import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import LoadingScreen from "../../component/loadingScreen";

const Layout = () => {
  const [change, setChange] = useState(false);
  const [page, setPage] = useState("phone");
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState("");
  const [order_loading, setOrderLoading] = useState(false);

  const path = useLocation().pathname;

  const closeWin = () => {
    window.parent.postMessage("retaino_close_iframe_window", "*");
  };

  useEffect(() => {
    if (change) {
      setTimeout(() => {
        if (page === "phone") {
          setPage("address");
        } else {
          setPage("phone");
        }
      }, 500);
      setTimeout(() => {
        setChange(false);
      }, 1000);
    }
  }, [change]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            {/* head */}
            {/* {path !== "/login" && (
              <div className={styles.card_head}>
                <Back name="close" value={"close"} onClick={closeWin} />
                <div className={styles.logo}></div>
                {path !== "/login" && (
                  <div className={styles.total}>₹2,300</div>
                )}
              </div>
            )} */}

            <div
              className={`${styles.overlay} ${change && styles.overlay_expand}`}
            ></div>

            {/* content section */}

            <div
              className={styles.card_content}
              style={{
                height: "90vh",
                overflowY: "hidden",
              }}
            >
              {path === "/login" && (
                <Cross className={styles.closeCross} onClick={closeWin} />
              )}
              <Outlet />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Layout;
