import { useEffect, useState } from "react";

import styles from "../../styles/checkout.module.css";
import lstyles from "../../styles/app.module.css";

import { ReactComponent as Cart } from "../../assets/shopping-cart.svg";
import { ReactComponent as Down } from "../../assets/arrow-down.svg";
import { ReactComponent as Coupon } from "../../assets/ticket-discount.svg";
import { ReactComponent as Truck } from "../../assets/truck.svg";
import { ReactComponent as AddCircle } from "../../assets/add-circle.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as User } from "../../assets/user.svg";
import { ReactComponent as Wallet } from "../../assets/wallet.svg";
import { ReactComponent as Lock } from "../../assets/lock.svg";
import { ReactComponent as Back } from "../../assets/arrow-left.svg";

import retaino from "../../assets/retaino.png";

import Overlay from "../../component/overlay";
import InputWithLable from "../../component/InputWithLable";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import DeliveryAddress from "../../component/deliveryAddress";
import PaymentMethod from "../../component/paymentMethod";
import AccountInfo from "../../component/accountInfo";
import BillingAddress from "../../component/billingAddress";
import BillingAddressForCheckout from "../../component/billingAddressForCheckout";

let key = "";

const Checkout = () => {
  const navigate = useNavigate();

  const [user, setUser]: any = useState({});
  const [products, setProducts]: any = useState([]);
  const [checkout, setCheckout]: any = useState(null);
  const [order_summary, setOrderSummary] = useState(false);
  const [address, setAddress] = useState(false);
  const [bAddress, setBAddress] = useState(false);
  const [payment, setPayment] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const [isDeliveryAddressSelected, setIsDeliveryAddressSelected] =
    useState(false);
  const [isPaymentMethodSelected, setIsPaymentMethodSelected] = useState(false);

  // personal info

  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [isBillingAddressAdded, setIsBillingAddressAdded] = useState(false);
  const [createNewBillingAddress, setCreateNewBillingAddress] = useState("");

  // billing Address State
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [coupon, setCoupon] = useState(false);

  const [billingAddress, setBillingAddress]: any = useState([]);
  const [shippingAddress, setShippingAddress]: any = useState([]);

  const [selectedDA, setSelectedDA] = useState(null);
  const [selectedBA, setSelectedBA] = useState(null);

  const [loading, setLoading] = useState(false);

  const id = useParams().id;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.parent.postMessage(`retaino_iframe_require_key`, "*");

    window.addEventListener("message", (e) => {
      if (typeof e.data === "string") {
        if (e.data.includes("cod_order_placed")) {
          console.log("placed");
          navigate("/order_success");
          setLoading(false);
        }

        if (e.data.includes("cod_order_error")) {
          console.log("error");
          setLoading(false);
        }

        if (e.data.includes("website_token")) {
          key = e.data.split(":")[1];
        }
      }
    });
  }, []);

  const getData = () => {
    // fetch data from api
    axios({
      url: "https://backend.retaino.in/api/checkouts/" + id,
      method: "GET",
    })
      .then((res: any) => {
        console.log(res.data.checkout);
        setCheckout(res.data.checkout);
        setProducts([...res.data.checkout.objects]);
        getUserData(
          res.data.checkout.userId._id,
          res.data.checkout.userId?.lastBillingAddress,
          res.data.checkout.userId?.lastShippingAddress
        );
      })
      .catch((err) => {
        console.log("error is :::: ", err);
      });
  };

  const getUserData = (
    id: any,
    lastBillingAddress: string | null,
    lastShippingAddress: string | null
  ) => {
    axios({
      url: "https://backend.retaino.in/api/users/getuserdetailsbyid/" + id,
      method: "GET",
    })
      .then((res) => {
        console.log(res);

        const data = res.data;
        setUser({ ...data, _id: id });
        setUserId(id);
        setEmail(data.email);

        setFirstName(data.firstName);
        setLastName(data.lastName);
        setDob(data.dob);
        setMobile(data?.mobile?.number || "");

        const bAddress = data.billingAddress;
        setName(bAddress[0]?.name);
        setAddress1(bAddress[0]?.addLineOne);
        setAddress2(bAddress[0]?.addLineTwo);
        setLandmark(bAddress[0]?.landmark);
        setPincode(bAddress[0]?.pincode);
        setCity(bAddress[0]?.city);
        setState(bAddress[0]?.state);
        setIsBillingAddressAdded(true);
        setCreateNewBillingAddress("new");
        setShippingAddress([...data.shippingAddress]);
        setBillingAddress([...data.billingAddress]);

        setSelectedBA(
          data.billingAddress.filter(
            (val: any) => val._id === lastBillingAddress
          ).length === 0
            ? null
            : data.billingAddress.filter(
                (val: any) => val._id === lastBillingAddress
              )[0]
        );

        setSelectedDA(
          data.shippingAddress.filter(
            (val: any) => val._id === lastShippingAddress
          ).length === 0
            ? null
            : data.shippingAddress.filter(
                (val: any) => val._id === lastShippingAddress
              )[0]
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeWin = () => {
    window.parent.postMessage("retaino_close_iframe_window", "*");
  };

  const checkFields = () => {
    if (!mobile) {
      toast.error("Mobile Number is required. please add it from Account Info");
      return false;
    }

    if (selectedBA === null) {
      toast.error("Please select billing address");
      return false;
    }

    if (selectedDA === null) {
      toast.error("Please select shipping address.");
      return false;
    }

    if (!paymentType) {
      toast.error("Please select payment method.");
      return false;
    }

    return true;
  };

  const handlePay = () => {
    if (checkFields()) {
      if (paymentType === "COD") {
        setLoading(true);
        window.parent.postMessage("user_requested_for_cod", "*");
      } else {
        axios({
          url: "https://backend.retaino.in/initiate_payment",
          method: "POST",
          data: {
            paymentGatewayName: "phonepe",
            transactionId: checkout.order.orderRefId,
            redirectUrl: "https://checkout.retaino.in/check_status",
            name: "Test",
            number: "6351974335",
            merchantUserId: user._id,
            amount: products
              .filter((val: any) => val?.objectPrice)
              .reduce((total: number, num: any) => total + num.objectPrice, 0),
            key: key,
            environment: "prod",
          },
        })
          .then((res: any) => {
            let url = res.data.response;
            let temp_transaction_id = res.data.txnId;
            localStorage.setItem("OREDER_TXN_ID", temp_transaction_id);
            // window.location.href = url;
            //@ts-ignore
            window?.PhonePeCheckout?.transact({
              tokenUrl: url,
              callback: (response: any) => {
                console.log("phonepe response is :::: ", response);
                if (response === "USER_CANCEL") {
                  return;
                } else if (response === "CONCLUDED") {
                  return;
                }
              },
              type: "IFRAME",
            });
          })
          .catch((err: any) => {
            console.log("error is ::: ", err);
            toast.error("Something went wrong please try in sometime.");
          });
      }
    }
  };

  return (
    <>
      <div className={lstyles.card_head}>
        <Back name="close" value={"close"} onClick={closeWin} />
        <div className={lstyles.logo}>{/* <img src={logo} alt="" /> */}</div>
        <div className={lstyles.total}>
          ₹
          {products
            .filter((val: any) => val?.objectPrice)
            .reduce((total: number, num: any) => total + num.objectPrice, 0)}
        </div>
      </div>
      <div className={styles.main}>
        {/* orders summary  */}
        <div className={styles.order_summary}>
          <div
            className={`${styles.order_summary_head} ${
              order_summary && styles.order_summary_head_expand
            }`}
            onClick={() => setOrderSummary((prev) => !prev)}
          >
            <Cart />
            <p>Order summary</p>
            <p style={{ marginLeft: "auto" }}>{products.length} item</p>
            <Down />
          </div>

          <div
            className={`${styles.order_summary_body} ${
              order_summary && styles.order_summary_body_expand
            }`}
          >
            <ul className={styles.orders}>
              {products.map((product: any, index: any) => {
                return (
                  <li key={index}>
                    <div className={styles.order_img_and_content}>
                      <div className={styles.order_img}>
                        <img src={product?.objectMediaUrl} />
                      </div>
                      <div className={styles.order_content}>
                        <h3>{product?.objectName}</h3>
                        {product?.objectVariant.length !== 0 &&
                          product?.objectVariant.map((val: any, index: any) => {
                            return (
                              <p style={{ color: "grey" }}>
                                {val?.options_name}:{" "}
                                {val?.options_value.length === 1
                                  ? val?.options_value
                                  : val?.options_value.join(", ")}
                              </p>
                            );
                          })}
                        <p style={{ color: "grey" }}>
                          qty : {product?.objectQuantity}
                        </p>
                      </div>
                    </div>
                    <div className={styles.order_price}>
                      ₹{product?.objectPrice}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* apply coupon */}
        {checkout?.discount.length !== 0 && (
          <div className={styles.coupon} onClick={() => setCoupon(true)}>
            <Coupon />
            <p>Apply Coupon</p>
            <ArrowRight />
          </div>
        )}

        {/* account info */}
        <AccountInfo data={user} fetchAgain={getData} />

        {/* billing address */}
        <BillingAddressForCheckout
          expand={bAddress}
          toggle={() => setBAddress((prev) => !prev)}
          userId={userId}
          address={billingAddress}
          setAddress={setBillingAddress}
          fetchAgain={getData}
          setSelectedBA={setSelectedBA}
          selectedBA={selectedBA}
        />

        <DeliveryAddress
          expand={address}
          toggle={() => setAddress((prev) => !prev)}
          userId={userId}
          address={shippingAddress}
          setAddress={setShippingAddress}
          fetchAgain={getData}
          setSelectedDA={setSelectedDA}
          selectedBA={selectedBA}
          selectedDA={selectedDA}
        />

        {/* payment method */}
        <PaymentMethod
          expand={payment}
          toggle={() => setPayment((prev) => !prev)}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />

        <button className={styles.proceed_btn} onClick={handlePay}>
          proceed to buy
        </button>

        <div className={styles.secured_section}>
          <p>Secured by</p>
          <img src={retaino} alt="retaino" />
        </div>

        {/* coupon overlay */}
        <Overlay
          title={"Apply Coupon"}
          open={coupon}
          onClose={() => setCoupon(false)}
        >
          <div className={styles.input}>
            <input type="text" placeholder="Enter Coupon Code" />
            <button>APPLY</button>
          </div>

          <ul className={styles.coupons}>
            {/* <li className={styles.coupon_card}> */}
            {/*   <div className={styles.coupon_head}> */}
            {/*     <h3>Coupon Name</h3> */}
            {/*     <span>CODE123</span> */}
            {/*     <button>APPLY</button> */}
            {/*   </div> */}
            {/*   <p> */}
            {/*     Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum */}
            {/*     adipisci cum animi quaerat maxime repellendus, ipsum tempora */}
            {/*   </p> */}
            {/* </li> */}
            {/* <li className={styles.coupon_card}> */}
            {/*   <div className={styles.coupon_head}> */}
            {/*     <h3>Coupon Name</h3> */}
            {/*     <span>CODE123</span> */}
            {/*     <button>APPLY</button> */}
            {/*   </div> */}
            {/*   <p> */}
            {/*     Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum */}
            {/*     adipisci cum animi quaerat maxime repellendus, ipsum tempora */}
            {/*   </p> */}
            {/* </li> */}
            {/* <li className={styles.coupon_card}> */}
            {/*   <div className={styles.coupon_head}> */}
            {/*     <h3>Coupon Name</h3> */}
            {/*     <span>CODE123</span> */}
            {/*     <button>APPLY</button> */}
            {/*   </div> */}
            {/*   <p> */}
            {/*     Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum */}
            {/*     adipisci cum animi quaerat maxime repellendus, ipsum tempora */}
            {/*   </p> */}
            {/* </li> */}
          </ul>
        </Overlay>
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            fontSize: ".9rem",
            color: "grey",
          }}
        >
          <span className="loader"></span>
          Please Wait, Placing your order
        </div>
      )}
    </>
  );
};

export default Checkout;
