import styles from "../styles/loadingScreen.module.css";

const LoadingScreen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container_head}>
        <div className={`${styles.logo} ${styles.loading}`}></div>
      </div>
      <div className={`${styles.container_border} ${styles.loading}`}></div>
      <div className={styles.container_content}>
        <ul>
          <li className={styles.loading}></li>
          <li className={styles.loading}></li>
          <li className={styles.loading}></li>
          <li className={styles.loading}></li>
        </ul>
      </div>
    </div>
  );
};

export default LoadingScreen;
