import React, { useEffect, useState } from "react";
import styles from "../styles/inputWithLabel.module.css";

type Props = {
  label: string;
  type: string;
  name: string;
  value: string | number | "";
  onChange: (event: any) => void;
};

const InputWithLable = (props: Props) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (props.value) {
      setState(true);
    }
  }, [props.value]);

  const handleBlur = (e: any) => {
    if (props.value) {
      setState(true);
    } else {
      setState(false);
      if (props.type === "date") {
        setState(true);
      }
    }
  };

  return (
    <div className={styles.iwl_container}>
      <p className={`${styles.iwl_label} ${state && styles.iwl_label_active}`}>
        {props.label}
      </p>
      <input
        type={props.type}
        value={props.value}
        onFocus={(e) => {
          if (props.type === "date") {
            e.target.type = "date";
          }
          setState(true);
        }}
        onBlur={handleBlur}
        onChange={props.onChange}
        name={props.name}
      />
    </div>
  );
};

export default InputWithLable;
